//react package
import { lazy,Suspense } from "react";
import { useRoutes,Navigate, useOutletContext } from "react-router";
import { Outlet, Link } from 'react-router-dom'

//loader & layout
import Loading from './Components/Loading'
import BasicLayout from './Layouts/BasicLayout'
import RegistrationLayout from "./Layouts/RegistrationLayout";

//pages
const Home = lazy(() => import(/* webpackChunkName: "Home" */ './Pages/Home'))
const VirtualRun = lazy(() => import(/* webpackChunkName: "Home" */ './Pages/VirtualRun'))
const Gallery = lazy(() => import(/* webpackChunkName: "Home" */ './Pages/Gallery'))
const ResultOffline = lazy(() => import(/* webpackChunkName: "Home" */ './Pages/ResultOffline'))
const ResultVirtual = lazy(() => import(/* webpackChunkName: "Home" */ './Pages/ResultVirtual'))


const ContactUs = lazy(() => import(/* webpackChunkName: "Home" */ './Pages/ContactUs'))
const Greeting = lazy(() => import(/* webpackChunkName: "Home" */ './Pages/Greeting'))
const Information = lazy(() => import(/* webpackChunkName: "Home" */ './Pages/Information'))
const Participant = lazy(() => import(/* webpackChunkName: "Home" */ './Pages/Participant'))
const Faq = lazy(() => import(/* webpackChunkName: "Home" */ './Pages/Faq'))

const VirtualStep1 = lazy(() => import(/* webpackChunkName: "Home" */ './Pages/registration/virtual/Step1'))
const VirtualStep2 = lazy(() => import(/* webpackChunkName: "Home" */ './Pages/registration/virtual/Step2'))
const VirtualStep3 = lazy(() => import(/* webpackChunkName: "Home" */ './Pages/registration/virtual/Step3'))

const OfflineStep1 = lazy(() => import(/* webpackChunkName: "Home" */ './Pages/registration/offline/Step1'))
const OfflineStep2 = lazy(() => import(/* webpackChunkName: "Home" */ './Pages/registration/offline/Step2'))
const OfflineStep3 = lazy(() => import(/* webpackChunkName: "Home" */ './Pages/registration/offline/Step3'))
const OfflineStep4 = lazy(() => import(/* webpackChunkName: "Home" */ './Pages/registration/offline/Step4'))

const Payment = lazy(() => import(/* webpackChunkName: "Home" */ './Pages/registration/Payment'))


const NotFound = lazy(() => import(/* webpackChunkName: "Home" */ './Pages/error/NotFound'))

const RouteList = ()=>{
    return useRoutes([
        {
            path:"/",
            element: <BasicLayout></BasicLayout>,
            children:[
                {
                    path:'/',
                    element: <Home></Home>
                },
                {
                    path:'/virtualrun',
                    element: <VirtualRun></VirtualRun>
                },
                {
                    path:'/greeting',
                    element: <Greeting></Greeting>
                },
                {
                    path:'/information',
                    element: <Information></Information>
                },
                {
                    path:'/faq',
                    element: <Faq></Faq>
                },
                {
                    path:'/participant',
                    element: <Participant></Participant>
                },
                {
                    path:'/gallery',
                    element: <Gallery></Gallery>
                },
                {
                    path:'/offline-result',
                    element: <ResultOffline></ResultOffline>
                },
                {
                    path:'/virtual-result',
                    element: <ResultVirtual></ResultVirtual>
                },
                {
                    path:'/contactus',
                    element: <ContactUs></ContactUs>
                },
                // {
                //     path:'/registration/offline/step-1',
                //     element:<OfflineStep1></OfflineStep1>
                // },
                // {
                //     path:'/registration/offline/step-2',
                //     element:<OfflineStep2></OfflineStep2>
                // },
                // {
                //     path:'/registration/offline/step-3',
                //     element:<OfflineStep3></OfflineStep3>
                // },
                // {
                //     path:'/registration/offline/step-4',
                //     element:<OfflineStep4></OfflineStep4>
                // },
                // {
                //     path:'/registration/virtual/step-1',
                //     element:<VirtualStep1></VirtualStep1>
                // },
                // {
                //     path:'/registration/virtual/step-2',
                //     element:<VirtualStep2></VirtualStep2>
                // },
                // {
                //     path:'/registration/virtual/step-3',
                //     element:<VirtualStep3></VirtualStep3>
                // },
                {
                    path:'*',
                    element:<Navigate to={"/404"}></Navigate>
                }
            ]
        },
        {
            path:"/registration",
            element: <><Outlet></Outlet></>,
            children:[
                {
                    path:'/registration/offline',
                    element: <><RegistrationLayout></RegistrationLayout></>,
                    children:[
                        {
                            path:'/registration/offline/step-1',
                            element:<OfflineStep1></OfflineStep1>
                        },
                        {
                            path:'/registration/offline/step-2',
                            element:<OfflineStep2></OfflineStep2>
                        },
                        {
                            path:'/registration/offline/step-3',
                            element:<OfflineStep3></OfflineStep3>
                        },
                        {
                            path:'/registration/offline/step-4',
                            element:<OfflineStep4></OfflineStep4>
                        },
                        {
                            path:'*',
                            element:<Navigate to={"/404"}></Navigate>
                        }
                    ]
                },
                {
                    path:'/registration/virtual',
                    element: <><RegistrationLayout></RegistrationLayout></>,
                    children:[
                        {
                            path:'/registration/virtual/step-1',
                            element:<VirtualStep1></VirtualStep1>
                        },
                        {
                            path:'/registration/virtual/step-2',
                            element:<VirtualStep2></VirtualStep2>
                        },
                        {
                            path:'/registration/virtual/step-3',
                            element:<VirtualStep3></VirtualStep3>
                        },
                        {
                            path:'*',
                            element:<Navigate to={"/404"}></Navigate>
                        }
                    ]
                },
                {
                    path:'/registration/payment/:orderid',
                    element: <><RegistrationLayout></RegistrationLayout></>,
                    children: [
                        {
                            path:'/registration/payment/:orderid',
                            element:<Payment></Payment>
                        },
                    ],                    
                },
                {
                    path:'*',
                    element:<Navigate to={"/404"}></Navigate>
                }
            ]
        },
        {
            path:"/404",
            element: (
                <Suspense fallback={<Loading text="Loading page ..." />}>
                  <NotFound />
                </Suspense>
              )
        }
    ])
}
export default RouteList